/* eslint-disable no-useless-escape */
<template>
  <div>
    <div class="pa-5">
      <v-titulo>Completar Cadastro</v-titulo>
    </div>
    <div class="design ml-10">
      <img src="@/assets/design/bubble.svg" alt="" />
    </div>
    <!-- class="perfilSection" -->
    <v-card class="mt-n12 mx-auto pa-4 lowShadow" max-width="800">
      <div class="fotoExposta text-center">
        <v-avatar class="mx-2 mt-n16" color="white" size="130">
          <v-icon size="45" v-if="uploaded == false"> mdi-image </v-icon>
          <img class="foto" v-else :src="user.fotoUrl" alt="" />
        </v-avatar>
      </div>

      <vStep :count="stepAtual" :count-max="countSteps" />

      <div class="frase pa-5 indigo--text text--darken-2">
        Quem nunca sonhou em um dia chegar lá não sabe o valor que tem uma
        vitória. A gente sonhou, acreditou e chegou aqui. Viva!
      </div>
      <!-- custom -->
      <div class="formulario">
        <div
          id="errosEncontrados"
          class="erros mx-0 mb-2 rounded-lg pa-4 red lighten-5"
          v-if="handles.length > 0"
        >
          <ul>
            <div class="font-weight-bold">Erros encontrados</div>
            <li class="item" :key="index" v-for="(handle, index) in handles">
              {{ handle.error }}
            </li>
          </ul>
        </div>
        <v-form ref="form" v-model="valid">
          <!-- FORMULARO 1 -->
          <div id="dadosParticipante" v-show="stepAtual === 1">
            <div class="text-h6 black--text text--darken-1 mb-2">
              Dados do Participante
            </div>
            <!-- class="flex form text-center justify-center" -->
            <v-row class="text-center">
              <v-col cols="12" sm="6">
                <v-text-field
                  class="justify-center"
                  label="* Nome"
                  :rules="campoVazioRules"
                  v-model="user.nome"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="justify-center"
                  label="* Sobrenome"
                  :rules="campoVazioRules"
                  v-model="user.sobreNome"
                />
              </v-col>
              <v-col cols="12" sm="6" class="text-center">
                <v-text-field
                  class="justify-center"
                  label="Mãe *"
                  v-model="user.nomeMae"
                />
              </v-col>
              <v-col cols="12" sm="6" class="text-center">
                <!--  -->
                <v-text-field
                  v-mask="'##/##/####'"
                  class="justify-center"
                  label="* Data de Nascimento"
                  :rules="campoVazioRules"
                  v-model="user.dataNascimento"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="justify-center"
                  label="* RG"
                  type="number"
                  v-model="user.rg"
                  :rules="campoVazioRules"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-mask="'###.###.###-##'"
                  class="justify-center"
                  label="* CPF"
                  v-model="user.cpf"
                  :rules="campoVazioRules"
                />
              </v-col>
            </v-row>
            <v-row class="text-center justify-center align-center">
              <v-col cols="12" sm="6">
                <div class="d-inline-block">
                  <v-checkbox color="primary" v-model="checkContrato">
                  </v-checkbox>
                </div>
                <div class="d-inline-block">
                  Aceito o
                  <a target="_contratoViewAPT" :href="contratoUrl">contrato</a>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="text-center">
                <v-btn
                  large
                  color="primary"
                  :disabled="!checkContrato"
                  @click="pipeline('VERIFICA_ETAPA_1')"
                  >Próximo</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <!-- FORMULARO 2 -->
          <div id="dadosAcesso" v-show="stepAtual === 2">
            <div class="text-h6 black--text text--darken-1 mb-2">
              Dados de Acesso
            </div>
            <v-row class="text-center" justify="center">
              <v-col cols="12">
                <div class="containerUnicInput">
                  <div>
                    <v-text-field
                      class="justify-center"
                      label="* Email"
                      disabled
                      inlined
                      v-model="user.email"
                    />
                  </div>
                  <div></div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="text-center">
                <v-text-field
                  class="justify-center"
                  label="Senha *"
                  v-model="user.senha"
                  :type="viewPass ? 'text' : 'password'"
                  :append-icon="viewPass ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="viewPass = !viewPass"
                />
              </v-col>
              <v-col cols="12" sm="6" class="text-center">
                <v-text-field
                  class="justify-center"
                  label="Confirmar Senha *"
                  v-model="confirmSenha"
                  :type="viewPass ? 'text' : 'password'"
                  :append-icon="viewPass ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="viewPass = !viewPass"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" class="text-center">
                <v-btn
                  large
                  color="grey darken-4"
                  class="white--text"
                  elevation="0"
                  @click="pipeline('RETORNA_TELA_ANTERIOR')"
                >
                  <v-icon>mdi-keyboard-return</v-icon></v-btn
                >
              </v-col>
              <v-col cols="6" sm="6">
                <center>
                  <v-btn
                    large
                    color="primary"
                    @click="pipeline('VERIFICA_ETAPA_2')"
                    >Próximo</v-btn
                  >
                </center>
              </v-col>
            </v-row>
          </div>

          <div id="endereco" v-show="stepAtual === 3" class="pa-5">
            <div class="text-h6 black--text text--darken-1">Endereço</div>
            <div class="text-subtitle-2 grey--text">
              Informe seu CEP, caso não seja encontrado, adicione manualmente.
            </div>
            <div class="pa-5">
              <div class="mx-auto text-center">
                <v-row>
                  <v-col cols="12" sm="12" class="text-center">
                    <div>
                      <v-text-field
                        solo
                        v-mask="'#####-###'"
                        label="CEP: 00000-000"
                        v-model="CEPSearch"
                        class="d-inline-block rounded-0"
                      >
                      </v-text-field>
                      <v-btn
                        :loading="loading"
                        color="primary"
                        class="pa-6 rounded-0"
                        @click="localizarCEP()"
                        >BUSCAR</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div
                class="pa-5 pb-8 text-subtitle-2 grey--text text--darken-1 text-center"
                v-if="CEPNaoEncontrado"
              >
                Não encontramos seu CEP, insira manualmente os dados nos campos
                a seguir
              </div>
              <div v-if="EnderecoOpen == true">
                <v-row v-if="!CEPNaoEncontrado">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="UF"
                      v-model="user.enderecoCompleto.estado"
                      :readonly="lookEspecifique"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Cidade"
                      v-model="user.enderecoCompleto.cidadeNome"
                      :readonly="lookEspecifique"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="CEPNaoEncontrado">
                  <v-col cols="12" md="6" sm="12">
                    <v-select
                      :items="listaEstados"
                      label="Estado *"
                      v-model="itemEstado"
                      return-object
                      item-text="nome"
                      item-value="uf"
                      @change="changeEstado()"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-select
                      :items="listaCidades"
                      label="Cidade * "
                      v-model="user.enderecoCompleto.cidadeNome"
                      item-text="descricao"
                      item-value="id"
                      :loading="loadingCidades"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Bairro *"
                      v-model="user.enderecoCompleto.bairro"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Rua"
                      v-model="user.enderecoCompleto.logradouro"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Número"
                      v-model="user.enderecoCompleto.numero"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Complemento"
                      v-model="user.enderecoCompleto.complemento"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row class="mt-10">
                <v-col cols="6" sm="6" class="text-left">
                  <v-btn
                    large
                    color="grey darken-4"
                    class="white--text"
                    elevation="0"
                    @click="pipeline('RETORNA_TELA_ANTERIOR')"
                  >
                    <v-icon>mdi-keyboard-return</v-icon></v-btn
                  >
                </v-col>
                <v-col cols="6" sm="6" v-if="PrimeiraBuscaFeita">
                  <center>
                    <v-btn
                      large
                      color="primary"
                      @click="pipeline('VERIFICA_ETAPA_3')"
                      >Próximo</v-btn
                    >
                  </center>
                </v-col>
              </v-row>
            </div>
          </div>

          <div id="foto" v-show="stepAtual === 4" class="text-center">
            <div class="pa-2 my-5">
              Precisamos de uma foto sua para seu perfil
            </div>
            <div class="text-truncate px-10">
              <v-file-input
                label="Foto para o perfil"
                outlined
                dense
                id="file"
                ref="file"
                truncate-length="35"
                v-on:change="changeAvatarToUpload()"
                accept="image/*"
                prepend-icon="mdi-camera"
              ></v-file-input>
            </div>
            <v-row class="mt-5">
              <v-col cols="12" md="2" class="text-center order-3 order-md-1">
                <div>
                  <v-btn
                    large
                    color="grey darken-4"
                    class="white--text"
                    elevation="0"
                    :loading="loadingUpload"
                    @click="pipeline('RETORNA_TELA_ANTERIOR')"
                  >
                    <v-icon>mdi-keyboard-return</v-icon></v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" md="5" class="text-center order-2 order-md-2">
                <v-btn
                  block
                  large
                  color="primary"
                  outlined
                  :loading="loadingUpload"
                  @click="pipeline('VERIFICA_ETAPA_4_SEMFOTO')"
                >
                  Pular esse processo</v-btn
                >
              </v-col>
              <v-col cols="12" md="5" class="text-center order-1 order-md-3">
                <v-btn
                  block
                  large
                  color="primary"
                  :loading="loadingUpload"
                  :disabled="uploaded == false"
                  @click="pipeline('VERIFICA_ETAPA_4_COMFOTO')"
                >
                  Pronto, Avançar!
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- caso seja menor de idade -->
          <div id="responsavel" v-show="stepAtual == 5" class="pa-5">
            <center>
              <h2 class="pb-5 ma-2">
                Responsável
              </h2>
            </center>
            <v-row class="text-center">
              <v-col cols="12" sm="6">
                <v-text-field
                  class="justify-center"
                  label="* Nome completo"
                  :rules="campoVazioRules"
                  v-model="user.responsavel.nome"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-mask="'###.###.###-##'"
                  class="justify-center"
                  label="* CPF"
                  v-model="user.responsavel.cpf"
                  :rules="campoVazioRules"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="justify-center"
                  label="* Email"
                  v-model="user.responsavel.email"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  class="justify-center"
                  label="* Telefone"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  v-model="user.responsavel.telefone"
                />
              </v-col>
            </v-row>
            <v-row class="mt-10">
              <v-col cols="6" sm="6" class="text-left">
                <v-btn
                  large
                  color="grey darken-4"
                  class="white--text"
                  elevation="0"
                  @click="pipeline('RETORNA_TELA_ANTERIOR')"
                >
                  <v-icon>mdi-keyboard-return</v-icon></v-btn
                >
              </v-col>
              <v-col cols="6" sm="6">
                <center>
                  <v-btn
                    large
                    color="primary"
                    @click="pipeline('VERIFICA_RESPONSAVEL')"
                    >Próximo</v-btn
                  >
                </center>
              </v-col>
            </v-row>
          </div>

          <div id="loading_foto" v-show="stepAtual == 6" class="pa-5">
            <center>
              <h2 class="py-8 ma-2">
                Aguarde enquanto estamos cuidado de tudo
              </h2>
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </center>
          </div>

          <!-- MENSAGEM DE OK -->
          <div v-show="stepAtual == 7">
            <center>
              <h2 class="py-5 px-4">Parabéns seu cadastro foi concluído!</h2>
            </center>
            <br /><br />
            <v-row>
              <v-col cols="12">
                <div class="text-center">
                  <v-btn
                    color="primary"
                    large
                    @click="goRoute('/aluno/dashboard/perfil')"
                  >
                    Entrar agora
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <br />
            <br />
            <br />
          </div>
        </v-form>
      </div>
    </v-card>
    <br />
    <br />
  </div>
</template>

<script>
import vStep from "@/components/customStep";
import userController from "@/middleware/userController.js";
import userAuth from "@/middleware/userAuth.js";
import Cadastros from "@/middleware/cadastros.js";
import serviceContrato from "@/middleware/contrato.js";
import serviceLocalidade from "@/middleware/localidadeController";
import moment from "moment";

export default {
  components: {
    vStep
  },
  data() {
    return {
      uploaded: false,
      loadingUpload: false,
      email: "",
      file: "",
      stepAtual: 1,
      countSteps: 7,
      existeResponsavel: false,
      valid: true,
      cpfValid: false,
      etapa3Valid: false,
      campoVazioRules: [v => !!v || "o campo está vazio"],
      confirmSenha: "",
      viewPass: false,
      checkContrato: false,
      contratoUrl: "",
      CEPSearch: "",
      CEPsend: "",
      EnderecoOpen: false,
      CEPNaoEncontrado: false,
      lookEspecifique: false,
      PrimeiraBuscaFeita: false,
      loadingCidades: false,
      loading: false,
      listaEstados: [],
      listaCidades: [],
      itemEstado: {},
      user: {
        cpf: "",
        nome: "",
        sobreNome: "",
        nomeFormando: "",
        fotoUrl: "",
        enderecoCompleto: {
          cep: ""
        },
        responsavel: {
          cpf: "",
          nome: "",
          sobreNome: "",
          email: "",
          telefone: ""
        }
      },
      handles: []
    };
  },
  computed: {
    devmode() {
      return process.env.NODE_ENV == "development";
    },
    computedPass() {
      if (this.viewPass) {
        return "text";
      } else {
        return "password";
      }
    }
  },
  watch: {
    CEPSearch: function(payloadCEP) {
      if (payloadCEP.length == 9) {
        let espect = payloadCEP.replace("-", "");
        this.$nextTick().then(() => {
          this.CEPsend = espect;
          this.user.enderecoCompleto.cep = espect;
        });
      } else {
        this.$nextTick(() => {
          this.CEPsend = "";
          this.user.enderecoCompleto.cep = "";
        });
      }
    }
  },
  methods: {
    buscaEstado() {
      serviceLocalidade.getEstado().then(resp => {
        this.listaEstados = resp.data;
      });
    },
    buscaCidade(uf) {
      serviceLocalidade
        .getCidadeForEstado(uf)
        .then(resp => {
          this.loadingCidades = false;
          this.listaCidades = resp.data;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loadingCidades = false;
        });
    },
    changeEstado() {
      this.loadingCidades = true;
      let ESTADO = this.itemEstado;
      this.user.enderecoCompleto.estado = ESTADO.uf;
      this.buscaCidade(ESTADO.id);
    },
    localizarCEP() {
      if (!this.CEPsend && this.CEPsend == null) return false;
      let cep = this.CEPsend;
      if (cep == null || cep == "") {
        this.$root.errorModal("CEP INVÁLIDO");
        this.EnderecoOpen = false;
        this.CEPNaoEncontrado = false;
        return false;
      }
      this.loading = true;
      let mergeData = {
        bairro: "",
        cep: cep,
        cidadeIbge: 0,
        cidadeNome: "",
        complemento: "",
        estado: "",
        logradouro: "",
        numero: ""
      };
      this.itemEstado = {};
      this.listaCidades = [];
      serviceLocalidade
        .getCep(cep)
        .then(resp => {
          let databin = resp.data;
          mergeData.bairro = databin.bairro;
          mergeData.cidadeIbge = databin.ibge;
          mergeData.cidadeNome = databin.localidade;
          mergeData.logradouro = databin.logradouro;
          mergeData.complemento = databin.complemento;
          mergeData.estado = databin.uf;
          this.lookEspecifique = true;
          this.user.enderecoCompleto = mergeData;
          this.EnderecoOpen = true;
          this.CEPNaoEncontrado = false;
          this.loading = false;
          this.PrimeiraBuscaFeita = true;
        })
        .catch(err => {
          //this.$root.errorModal(err.data.message);
          mergeData.bairro = "";
          mergeData.cidadeIbge = "";
          mergeData.estado = "";
          mergeData.cidadeNome = "";
          mergeData.logradouro = "";
          mergeData.complemento = "";
          this.CEPNaoEncontrado = true;
          this.user.enderecoCompleto = mergeData;
          this.lookEspecifique = false;
          this.EnderecoOpen = true;
          this.loading = false;
          this.PrimeiraBuscaFeita = true;
          console.log(err.data.message);
        });
    },
    validateForm() {
      this.$refs.form.validate();
    },
    goRoute(rota) {
      this.$router.push(rota);
    },
    RequestTokenAuth() {
      let email = this.user.email;
      let senha = this.user.senha;

      userAuth
        .login(email, senha, false)
        .then(response => {
          if (response.status == 200) {
            let token = response.data;
            this.$store.commit("aluno/setTokenAuth", token);
            localStorage.setItem(
              "JWT_APPDATURMA_ALUNO/token",
              token.accessToken
            );
            localStorage.setItem("JWT_APPDATURMA_ALUNO/type", token.tokenType);
          }
        })
        .then(() => {
          userController.getUsuario(email).then(response => {
            //this.$store.commit("aluno/setAuthStatus", true);
            this.$store.commit("aluno/setUserData", response.data);
            this.stepAtual = 7;
          });
        })
        .catch(error => {
          this.$root.errorModal(error.data);
        });
    },
    changeAvatarToUpload() {
      //define
      this.loadingUpload = true;
      let makefile = document.querySelector("#file").files[0]; //single file
      let formData = new FormData();
      formData.append("file", makefile);
      Cadastros.uploadAvatarNoAuth(formData)
        .then(resp => {
          this.user.fotoUrl = resp.data;
          this.$emit("onUpload", resp.data);
          this.$root.sucessoModal("Imagem enviada com sucesso");
          this.uploaded = true;
        })
        .finally(() => {
          this.loadingUpload = false;
        })
        .catch(err => {
          this.uploaded = false;
          this.$root.infoModal(err.data);
        });
    },
    cadastrarUsuario() {
      let user = { ...this.user };
      //user.nomeFormando =
      user.dataNascimento = moment(user.dataNascimento, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      this.stepAtual = 6;
      userController
        .setUsuario(user)
        .then(() => {
          this.RequestTokenAuth();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.stepAtual = 1;
        });
    },
    voltarEtapa(num) {
      this.stepAtual = num;
    },
    validaData(dataNasc) {
      if (dataNasc && dataNasc.length == 10) {
        let data = moment(dataNasc, "DD/MM/YYYY");
        if (data.isValid()) {
          if (data.isBefore(moment())) {
            return true;
          }
        }
      }
      return false;
    },
    verificaEtapa1() {
      let user = { ...this.user };
      let userCPF = user.cpf;
      let validCPF = this.$root.validateCPF(userCPF);
      if (!this.validaData(user.dataNascimento)) {
        this.handles.push({
          error:
            "Data de Nascimento não está conforme dd/mm/aaaa (exemplo 01/02/2000)"
        });
      }
      if (!this.user.nome || this.user.nome.length < 3) {
        this.handles.push({ error: "Nome está com menos de três letras" });
      }
      if (!this.user.sobreNome || this.user.sobreNome.length < 3) {
        this.handles.push({ error: "Sobrenome não está conforme" });
      }
      if (!user.nomeMae || user.nomeMae.length < 3) {
        this.handles.push({ error: "Nome da mãe está inválido" });
      }
      if (
        !this.user.rg ||
        this.user.rg.length < 5 ||
        this.user.rg.length > 30
      ) {
        this.handles.push({ error: "RG não está valido" });
      }
      if (!userCPF || !validCPF) {
        this.handles.push({ error: "CPF não está valido" });
      }
      if (this.handles.length > 0) {
        return false;
      }
      this.verificaResponsavel();
      this.stepAtual = 2;
    },
    verificaResponsavel() {
      let valorFormatado = moment(this.user.dataNascimento, "DD/MM/YYYY");
      let result = moment().diff(valorFormatado, "years");
      if (result < 18) {
        this.existeResponsavel = true;
      } else {
        this.existeResponsavel = false;
      }
    },
    verificaEtapa2() {
      var user = this.user;

      this.user.senha = String(this.user.senha);
      this.confirmSenha = String(this.confirmSenha);
      var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

      if (!this.user.email) {
        this.handles.push({ error: "Email não consta no nosso sistema" });
      }
      if (!regex.test(user.senha)) {
        this.handles.push({
          error: "Senha deve conter 8 digitos sendo numeros e letras"
        });
      }
      if (!this.confirmSenha || this.confirmSenha !== user.senha) {
        this.handles.push({
          error: "Senha não confirmada ou divergente, digite novamente."
        });
      }
      if (this.handles.length > 0) {
        return false;
      }
      this.stepAtual = 3;
    },
    verificaEtapa3() {
      let userCurrentAddress = this.user.enderecoCompleto;
      this.handles = [];
      this.verifyUnity("rua", userCurrentAddress.logradouro, 1);
      this.verifyUnity("bairro", userCurrentAddress.bairro, 3);
      this.verifyUnity("cidade", userCurrentAddress.cidadeNome, 3);
      this.verifyUnity("UF", userCurrentAddress.estado, 2);
      this.verifyUnity("CEP", userCurrentAddress.cep, 8);
      if (this.handles.length > 0) {
        this.$nextTick(() => {
          window.location.hash = "#errosEncontrados";
          this.$root.errorModal("Foi encontrado erro, verifique a lista acima");
          return false;
        });
      } else {
        this.stepAtual = 4;
      }
    },
    verificaEtapaResponsavel() {
      this.handles = [];
      let responsavel = this.user.responsavel;
      let userCPF = this.user.responsavel.cpf;
      let validCPF = this.$root.validateCPF(userCPF);
      let regexEmail = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);

      if (!responsavel.nome || responsavel.nome.length < 4) {
        this.handles.push({
          error: "Nome do responsável deve conter sobrenome"
        });
      }
      if (!responsavel.email || !regexEmail.test(responsavel.email)) {
        this.handles.push({ error: "Email não está no formato correto" });
      }
      if (
        !responsavel.telefone ||
        responsavel.telefone.replace(/[^0-9]/g, "").length < 10
      ) {
        this.handles.push({
          error:
            "Telefone inválido, exemplos a seguir: (00) 0000-0000 ou (00) 00000-0000"
        });
      }
      if (!userCPF || !validCPF) {
        this.handles.push({ error: "CPF não está valido" });
      }
      if (this.handles.length > 0) {
        this.$nextTick(() => {
          window.location.hash = "#errosEncontrados";
          this.$root.errorModal("Foi encontrado erro, verifique a lista acima");
          return false;
        });
      } else {
        this.cadastrarUsuario();
      }
    },
    verifyUnity(name, valor, qtdMin) {
      if (!valor || valor == null || valor == "") {
        this.handles.push({ error: `o campo ${name} está inválido` });
        return;
      }
      if (!qtdMin || qtdMin == "") {
        return;
      }
      if (valor.length < qtdMin) {
        this.handles.push(`o campo ${name} está inválido`);
      }
    },
    pipeline(name) {
      this.handles = [];
      if (name == "RETORNA_TELA_ANTERIOR") {
        this.handles = [];
        if (this.stepAtual > 1) {
          this.stepAtual--;
        } else {
          this.$root.errorModal("Erro no stepAtual");
        }
      } else if (name == "VERIFICA_ETAPA_1") {
        this.verificaEtapa1();
      } else if (name == "VERIFICA_ETAPA_2") {
        this.verificaEtapa2();
      } else if (name == "VERIFICA_ETAPA_3") {
        this.verificaEtapa3();
      } else if (name == "VERIFICA_ETAPA_4_COMFOTO") {
        if (this.existeResponsavel) {
          this.stepAtual = 5;
        } else {
          this.cadastrarUsuario();
        }
      } else if (name == "VERIFICA_ETAPA_4_SEMFOTO") {
        this.user.fotoUrl = "";
        if (this.existeResponsavel) {
          this.stepAtual = 5;
        } else {
          this.cadastrarUsuario();
        }
      } else if (name == "VERIFICA_RESPONSAVEL") {
        this.verificaEtapaResponsavel();
      }
    },
    Guardian() {
      this.$router.push("/401");
    },
    UserPayload(codigo) {
      userController
        .getUsuarioViaHash(codigo)
        .then(response => {
          if (response.status == 200) {
            this.user = response.data;
            if (!response.data.responsavel) {
              this.user.responsavel = {
                nome: "",
                sobreNome: "",
                email: "",
                telefone: ""
              };
            }
            this.user.enderecoCompleto = {
              cep: ""
            };
            this.user.codigo = this.$route.params.codigo;
            setTimeout(() => {
              this.validateForm();
            }, 200);
          }
        })
        .catch(() => {
          this.Guardian();
        });
    },
    UserExists(codigo) {
      userController
        .getUsuarioViaHash(codigo)
        .then(response => {
          if (response.status == 200) {
            let user = response.data;
            if (user.situacaoCadastro !== "INCOMPLETO") {
              this.Guardian();
            }
          } else {
            this.Guardian();
          }
        })
        .catch(() => {
          this.Guardian();
        });
    }
  },
  mounted() {
    let codigo = this.$route.params.codigo;
    this.UserPayload(codigo);
  },
  beforeMount() {
    this.$store.commit("aluno/setTokenAuth", null);
    localStorage.removeItem("JWT_APPDATURMA_ALUNO/token");
    localStorage.removeItem("JWT_APPDATURMA_ALUNO/type");
    let codigo = this.$route.params.codigo;
    this.UserExists(codigo);
    serviceContrato.getContratoUrl().then(resp => {
      this.contratoUrl = resp.data;
    });
    this.buscaEstado();
  }
};
</script>

<style lang="scss" scoped>
.containerUnicInput {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.erros {
  padding: 6px;
  .item {
    padding: 5px;
    margin: 2px;
    color: rgba(177, 10, 10, 1);
    font-size: 13px;
  }
}
.frase {
  text-align: center;
  max-width: 90%;
  margin: 5px auto;
}
.design {
  height: 100px;
}
.perfilSection {
  background-color: rgb(255, 255, 255);
  display: block;
  position: relative;
  margin: 0px auto;
  padding: 20px;
  min-width: auto;
  width: 83%;
  margin-top: -100px;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 2px rgba(50, 50, 50, 0.1);
  /*.fotoExposta {
    text-align: center;

    .circulo {
      width: 126px;
      height: 126px;
      margin-top: -100px;
      //object-fit: cover;
      border-radius: 100px;
      font-size: 100px;
      background-color: white;
      display: inline-grid;
      vertical-align: center;
      text-align: center;
      box-shadow: 0px 0px 10px 0px $colorLightGrey;
      align-content: center;
      align-items: center;
      align-self: center;
      .foto {
        width: 126px;
        height: 126px;
        border-radius: 100px;
      }
      span {
        color: $colorLightPurble;
        font-size: 45px;
      }
    }
  }*/
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    .scope {
      .label {
        font-size: 11px;
        font-weight: $fontDashMedium;
        color: $colorDarkPurple;
        padding: 5px;
        height: 15px;
      }
      .input {
        font-size: 13px;
        font-weight: $fontDashRegular;
        &::placeholder {
          font-size: 13px;
          font-weight: $fontDashMedium;
        }
      }
    }
  }
}
</style>
