<template>
  <div class="layoutAluno">
    <div class="header lowShadow">
      <div class="d-inline-block pa-2 px-5">
        <img
          src="@/assets/logo-deitada.png"
          style="width: 180px; magin: -5px"
        />
      </div>
    </div>
    <div class="conteudo">
      <vPerfil @onUpload="changePerfil" />
    </div>
  </div>
</template>

<script>
import vPerfil from "./perfil";
export default {
  data() {
    return {
      foto: "",
      fotoAtiva: false
    };
  },
  components: {
    vPerfil
  },
  methods: {
    changePerfil(imagemPerfil) {
      this.foto = imagemPerfil;
      this.fotoAtiva = true;
    }
  },
  computed: {
    renderImagePerfil() {
      return "https://image.freepik.com/fotos-gratis/linda-mulher-afro-americana-esta-de-perfil-vestida-casualmente-tem-um-sorriso-largo-e-brilhante-feliz-por-ser-fotografada-isolada-sobre-uma-parede-branca-conceito-de-emocoes-e-sentimentos-positivos_273609-16456.jpg";
    },
    getMenu() {
      return this.$store.getters["aluno/getMenuToggle"];
    }
  }
};
</script>

<style lang="scss" scoped>
.layoutAluno {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "menu header" "menu conteudo";
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  background-color: #f5f6f8;
  .menuDashboard {
    grid-area: menu;
    transition: 0.5s all;
  }
  .header {
    grid-area: header;
  }
  .conteudo {
    grid-area: conteudo;
  }
}
</style>
<style lang="scss" scoped>
//header
.header {
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 5px 0px 4px 4px rgba(0, 0, 0, 0.2);
  //padding: 10px;
  //max-height: 61px;
  padding: 4px;
  padding-top: 6px;
  align-content: center;
  align-items: center;
  .uiUser {
    display: grid;
    grid-template-columns: auto auto auto;
    align-content: center;
    align-items: center;
    align-self: center;
    grid-gap: 4px;
    .fotoPerfil {
      align-content: center;
      align-items: center;
      align-self: center;
      .fotoScope {
        object-fit: cover;
        background-color: rgba(50, 50, 50, 0.1);
        width: 49px;
        height: 49px;
        border-radius: 100px;
        text-align: center;
        align-content: center;
        align-items: center;
        align-self: center;
        vertical-align: center;
        display: inline-grid;
        span {
          color: $colorLightPurble;
        }
      }
    }
    .userName {
      padding: 10px;
      color: $colorDarkGrey;
    }
    .notificacao {
      color: $colorDarkGrey;
    }
  }
}
//MENU
$dashWidthMin: 100px;
$dashWidthMax: 256px;
$hoverlink: rgba(50, 50, 50, 0.05);
.menuDashboard {
  background-color: white;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
  width: $dashWidthMax;
  .splash {
    .initial {
      padding: 20px;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 10px;
      .signal {
        cursor: hover;
      }
    }
    .minimal {
      .perfilDash {
        width: 60px;
        height: 60px;
        margin: 10px auto;
        display: grid;
        padding: 0px;
        border-radius: 100px;
        background-color: rgba(50, 50, 50, 0.1);
        max-width: 100%;
        object-position: center;
        text-align: center;
        align-content: center;
        align-items: center;
        align-self: center;
        span {
          color: $colorLightPurble;
        }
        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 100px;
        }
      }
    }
  }
  .menu {
    .link {
      font-size: 13px;
      font-weight: $fontDashMedium;
      text-decoration: none;
      display: grid;
      grid-template-columns: 50px 1fr;
      padding: 10px 10px;
      text-align: center;
      border-right: 4px solid rgba(0, 0, 0, 0);
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      align-content: center;
      align-items: center;
      align-self: center;
      text-align: left;
      transition: 0.3s all;
      .icon {
        color: $colorLightGrey;
        transition: 0.3s all;
      }
      .text {
        color: $colorLightGrey;
        transition: 0.3s all;
      }
      &:hover {
        background-color: $hoverlink;
        //div.icon {}
        //div.text {}
      }
      &.router-link-active,
      .preativo {
        border-right: 4px solid $colorDarkPurple;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        & > .icon {
          color: $colorDarkPurple;
        }
        & > .text {
          color: $colorDarkPurple;
        }
      }
    }
  }
  &.menuMinimizado {
    width: $dashWidthMin;
    .link {
      grid-template-columns: 1fr;
      text-align: center;
      .text {
        display: none;
        transition: 2.5s all;
      }
      .link {
        color: $colorLightPurble;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
